import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("focusout", this.scroll.bind(this))
  }

  scroll() {
    window.scrollTo(0, 0)
  }
}
