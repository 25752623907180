import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textarea", "form", "checkbox"]

  connect() {
    this.updateButtonDisabledState()
  }

  checkboxChanged() {
    this.updateTextAreaValue()
    this.formTarget.requestSubmit()
    this.updateButtonDisabledState()
  }

  updateTextAreaValue() {
    const data = {}
    this.checkedCheckboxes.forEach((input) => {
      data[input.dataset.label] = parseInt(input.value)
    })
    this.textareaTarget.innerText = JSON.stringify(data)
  }

  updateButtonDisabledState() {
    this.nextButton.disabled = this.checkedCheckboxes.length < 2
  }

  get checkedCheckboxes() {
    return this.checkboxTargets.filter((input) => input.checked)
  }

  get nextButton() {
    return document.querySelector("[data-behaviour=next-button]")
  }
}
