import { BridgeComponent } from "@hotwired/strada"
import { post } from "@rails/request.js"

export default class extends BridgeComponent {
  static component = "notifications"

  static targets = ["disabledMessage", "checkbox"]

  connect() {
    this.requestNotificationStatus()
  }

  checkboxValueChanged() {
    this.requestAuthorizationForNotifications()
  }

  requestNotificationStatus() {
    this.send(
      "requestNotificationStatus",
      {},
      this.requestNotificationStatusCallback.bind(this),
    )
  }

  async requestNotificationStatusCallback(payload) {
    if (this.hasDisabledMessageTarget) {
      this.disabledMessageTarget.classList.toggle(
        "hidden",
        payload.data.enabled,
      )
    }
  }

  requestAuthorizationForNotifications() {
    this.send(
      "requestAuthorizationForNotifications",
      {},
      this.requestAuthorizationForNotificationsCallback.bind(this),
    )
  }

  async requestAuthorizationForNotificationsCallback(payload) {
    if (payload.data.success) {
      this.handleRequestAuthorizationForNotificationsSuccess(payload.data)
    }
    this.requestNotificationStatus()
  }

  async handleRequestAuthorizationForNotificationsSuccess(data) {
    const result = await post("/settings/devices", {
      body: { token_base64: data.deviceTokenBase64 },
      contentType: "application/json",
    })
    if (!result.ok) {
      // FIXME: Display a message to the user
      this.checkboxTarget.checked = false
    }
  }
}
