import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.inputTarget.addEventListener("keydown", this.validate.bind(this))
  }

  validate(event) {
    if (event.key === "Backspace" || event.key.match(/[\d,.]/)) return true

    event.preventDefault()
  }
}
