import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["star", "starsInput", "form"]
  static values = { stars: Number }

  rate(event) {
    this.starsValue = event.params.stars
  }

  starsValueChanged(stars) {
    this.updateStarsVisibility()
    this.starsInputTarget.value = stars
    this.formTarget.requestSubmit()
  }

  updateStarsVisibility() {
    this.starTargets.forEach((star) => {
      const filled = parseInt(star.dataset.ratingsStarsParam) <= this.starsValue
      star
        .querySelector("[data-icon-filled=true]")
        .classList.toggle("hidden", !filled)
      star
        .querySelector("[data-icon-blank=true]")
        .classList.toggle("hidden", filled)
    })
  }
}
