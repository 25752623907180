import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import "swiper/css"

export default class extends Controller {
  static targets = ["swiper"]
  static values = {
    initialSlide: { type: Number, default: 0 },
  }

  connect() {
    this.swiper = new Swiper(this.swiperTarget, {
      direction: "vertical",
      on: {
        slideChange: this.handleSlideChange.bind(this),
        init: this.setProgress.bind(this),
      },
      initialSlide: this.initialSlideValue,
    })
    document.documentElement.classList.add("overscroll-none")
  }

  disconnect() {
    document.documentElement.classList.remove("overscroll-none")
  }

  handleSlideChange(swiper) {
    const previousSlide = swiper.slides[swiper.activeIndex - 1]
    if (previousSlide) {
      const hadNotificationsScreen =
        previousSlide.querySelectorAll(
          "[data-controller=bridge--notifications]",
        ).length === 1
      if (hadNotificationsScreen) {
        const form = document.forms["edit-notifications"]
        form.requestSubmit()
      }
    }
    this.setProgress()
  }

  setProgress() {
    if (undefined === this.swiper) {
      // Workaround for a bug when returning from user medication. Somehow the swiper is initialized twice.
      setTimeout(this.setProgress.bind(this), 200)
      return false
    }
    const progress =
      ((this.swiper.activeIndex + 1) * 100) / this.swiper.slides.length
    this.dispatch("progress", { detail: { progress } })
  }

  next() {
    this.swiper.slideNext()
  }
}
