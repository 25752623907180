import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textarea"]

  connect() {
    this.textareaTarget.addEventListener("blur", this.saveChanges.bind(this))
  }

  saveChanges() {
    this.element.requestSubmit()
  }
}
