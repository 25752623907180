// https://floating-ui.com/docs/tutorial

import { Controller } from "@hotwired/stimulus"
import { computePosition, offset } from "@floating-ui/dom"

export default class extends Controller {
  static values = {
    position: String,
  }

  connect() {
    document.addEventListener("click", () => this.element.remove())
    const parent = this.element.closest(".relative")
    const tooltip = this.element
    const verticalOffsetInPx = 6

    computePosition(parent, tooltip, {
      placement: this.positionValue,
      middleware: [offset(verticalOffsetInPx)],
    }).then(({ x, y }) => {
      Object.assign(tooltip.style, { left: `${x}px`, top: `${y}px` })
    })
  }
}
