import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  remove() {
    const currentMeals = this.element.parentElement.querySelectorAll(
      "[data-controller='meal']",
    ).length
    if (currentMeals > 1) {
      const target = this.element.parentElement
      this.element.remove()
      this.dispatch("remove", { target })
    }
  }
}
