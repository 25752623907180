import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["iconHidden", "iconShown", "input"]
  static classes = ["hidden"]
  static values = {
    revealed: { type: Boolean, default: false },
  }

  toggle() {
    this.revealedValue = !this.revealedValue
  }

  revealedValueChanged(revealed) {
    this.inputTarget.type = this.revealedValue ? "text" : "password"
    this.iconShownTarget.classList.toggle(this.hiddenClass, revealed)
    this.iconHiddenTarget.classList.toggle(this.hiddenClass, !revealed)
  }
}
