import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("touchstart", this.startSwiping.bind(this))
    this.element.addEventListener("touchend", this.snap.bind(this))
  }

  startSwiping(event) {
    this.startX = this.element.scrollLeft
  }

  snap() {
    const minScroll = 50
    const scrollDelta = Math.abs(this.element.scrollLeft - this.startX)
    const scrolledEnough = scrollDelta > minScroll
    const swipedRight = this.element.scrollLeft > this.startX
    const swipedLeft = this.element.scrollLeft < this.startX

    if (swipedRight) {
      scrolledEnough ? this.open() : this.close()
    } else if (swipedLeft) {
      scrolledEnough ? this.close() : this.open()
    }
  }

  close() {
    this.closeSlider(this.element)
  }

  closeSlider(element) {
    element.scroll({ left: 0, behavior: "smooth" })
  }

  open() {
    this.otherSliders().forEach((element) => this.closeSlider(element))
    this.element.scroll({ left: this.element.scrollWidth, behavior: "smooth" })
  }

  otherSliders() {
    return document.querySelectorAll("[data-controller~=action-slider]")
  }
}
