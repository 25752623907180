import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["overlay", "turboFrame"]
  static values = {
    currentSlug: {
      type: String,
      default: "",
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  }

  load({ params: { slug } }) {
    this.currentSlugValue = slug
    // shift player position when player is set in coaching overlay
    // and dis-elevate reader overlay
    // (refactor later)
    this.readerOverlay.classList.add("!z-70")
  }

  currentSlugValueChanged(current, previous) {
    this.updateTurboFrameTargetSrc()
    this.updateOverlayVisiblity()
  }

  currentPageValueChanged(current, previous) {
    this.updateTurboFrameTargetSrc()
  }

  overlayShouldBeLoaded() {
    return this.currentSlugValue.length > 0
  }

  close() {
    this.currentSlugValue = ""
    this.currentPageValue = 1
    this.turboFrameTarget.innerHTML = ""
    this.playerBar.style = ""
    this.playerController.dataset.discoverPlayerCurrentSoundUrlValue = ""
  }

  nextPage() {
    this.currentPageValue = this.currentPageValue += 1
  }

  previousPage() {
    this.currentPageValue = this.currentPageValue -= 1
  }

  updateOverlayVisiblity() {
    this.overlayTarget.classList.toggle("hidden", !this.overlayShouldBeLoaded())
  }

  updateTurboFrameTargetSrc() {
    if (this.overlayShouldBeLoaded()) {
      this.turboFrameTarget.src = `/coachings/${this.currentSlugValue}/${this.currentPageValue}`
    }
  }

  get playerController() {
    return document.querySelector("[data-controller~=discover-player]")
  }

  get playerBar() {
    return document.querySelector("[data-discover-player-target=playerBar]")
  }

  get readerOverlay() {
    return document.querySelector("[data-discover-player-target=readerOverlay]")
  }
}
