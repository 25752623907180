import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  track(event) {
    const eventName = event.currentTarget.dataset.eventName

    post(`/events?action_name=${eventName}`, {
      body: { details: {} },
      contentType: "application/json",
    })
  }
}
