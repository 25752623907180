import { Controller } from "@hotwired/stimulus"
import DraggablePiechart from "@/javascript/draggable-piechart.js"

export default class extends Controller {
  static targets = ["chart", "hint", "answer", "form"]
  static values = {
    items: {
      type: Array,
      default: [],
    },
    labels: {
      type: Array,
      default: [],
    },
    percentages: {
      type: Array,
      default: [],
    },
  }

  connect() {
    const onChange = (object) => {
      this.percentagesValue = object
        .getAllSliceSizePercentages()
        .map((value) => Math.round(value))
    }
    this.pieChart = new DraggablePiechart({
      canvas: this.chartTarget,
      proportions: this.itemsValue,
      onchange: onChange,
    })

    this.hintTarget.addEventListener("click", () => {
      this.hintTarget.classList.add("hidden")
      localStorage.setItem("piechart-utilization-hint-seen", true)
    })

    localStorage.getItem("piechart-utilization-hint-seen") &&
      this.hintTarget.classList.add("hidden")
  }

  percentagesValueChanged(current, previous) {
    const data = {}
    current.forEach((value, index) => {
      data[this.labelsValue[index]] = value
    })
    this.answerTarget.value = JSON.stringify(data)
    this.debounce(() => this.submitForm())()
  }

  submitForm() {
    this.formTarget.requestSubmit()
  }

  debounce(func, timeout = 300) {
    return (...args) => {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        func.apply(this, args)
      }, timeout)
    }
  }
}
