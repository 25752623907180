/**
 * Created by james on 23/02/2017.
 */

;(function () {
  const extend = function (out) {
    out = out || {}

    for (let i = 1; i < arguments.length; i++) {
      if (!arguments[i]) continue

      for (const key in arguments[i]) {
        if (arguments[i].hasOwnProperty(key)) out[key] = arguments[i][key]
      }
    }

    return out
  }

  const DraggablePiechart = function (setup) {
    const piechart = this

    setup = extend({}, this.defaults, setup)

    this.canvas = setup.canvas
    this.context = setup.canvas.getContext("2d")

    if (!this.context) {
      console.log("Error: DraggablePiechart needs an html5 canvas.")
      return
    }

    if (setup.proportions) {
      this.data = generateDataFromProportions(setup.proportions)
    } else if (setup.data) {
      this.data = setup.data
    }

    this.draggedPie = null
    this.hoveredIndex = -1
    this.radius = setup.radius
    this.collapsing = setup.collapsing
    this.minAngle = setup.minAngle
    this.drawSegment = setup.drawSegment
    this.drawNode = setup.drawNode
    this.onchange = setup.onchange

    // Bind appropriate events

    this.canvas.addEventListener("touchstart", function (e) {
      touchStart(e)
      e.preventDefault()
    })
    this.canvas.addEventListener("touchmove", function (e) {
      touchMove(e)
      e.preventDefault()
    })
    document.addEventListener("touchend", function (e) {
      touchEnd(e)
    })

    this.canvas.addEventListener("mousedown", touchStart)
    this.canvas.addEventListener("mousemove", touchMove)
    document.addEventListener("mouseup", touchEnd)

    this.draw()

    function touchStart(event) {
      piechart.draggedPie = piechart.getTarget(getMouseLocation(event))
      if (piechart.draggedPie) {
        piechart.hoveredIndex = piechart.draggedPie.index
      }
    }

    function touchEnd() {
      if (piechart.draggedPie) {
        piechart.draggedPie = null
        piechart.draw()
      }
    }

    function touchMove(event) {
      const dragLocation = getMouseLocation(event)

      if (!piechart.draggedPie) {
        const hoveredTarget = piechart.getTarget(dragLocation)
        if (hoveredTarget) {
          piechart.hoveredIndex = hoveredTarget.index
          piechart.draw()
        } else if (piechart.hoveredIndex != -1) {
          piechart.hoveredIndex = -1
          piechart.draw()
        }
        return
      }

      const draggedPie = piechart.draggedPie

      const dx = dragLocation.x - draggedPie.centerX
      const dy = dragLocation.y - draggedPie.centerY

      // Get angle of grabbed target from centre of pie
      const newAngle = Math.atan2(dy, dx) - draggedPie.angleOffset

      piechart.shiftSelectedAngle(newAngle)
      piechart.draw()
    }

    function getMouseLocation(evt) {
      const rect = piechart.canvas.getBoundingClientRect()

      if (evt.clientX) {
        return {
          x: (evt.clientX - rect.left) * 2,
          y: (evt.clientY - rect.top) * 2,
        }
      } else {
        return {
          x: (evt.targetTouches[0].clientX - rect.left) * 2,
          y: (evt.targetTouches[0].clientY - rect.top) * 2,
        }
      }
    }

    /*
     * Generates angle data from proportions (array of objects with proportion, format
     */
    function generateDataFromProportions(proportions) {
      // sum of proportions
      const total = proportions.reduce(function (a, v) {
        return a + v.proportion
      }, 0)

      // begin at 0
      let currentAngle = 0

      // use the proportions to reconstruct angles
      return proportions.map(function (v, i) {
        const arcSize = (TAU * v.proportion) / total
        const data = {
          angle: currentAngle,
          format: v.format,
          collapsed: arcSize <= 0,
        }
        currentAngle = normaliseAngle(currentAngle + arcSize)
        return data
      })
    }
  }

  /*
   * Move angle specified by index: i, by amount: angle in rads
   */
  DraggablePiechart.prototype.moveAngle = function (i, amount) {
    if (this.data[i].collapsed && amount < 0) {
      this.setCollapsed(i, false)
      return
    }

    const geometry = this.getGeometry()
    this.draggedPie = {
      index: i,
      angleOffset: 0,
      centerX: geometry.centerX,
      centerY: geometry.centerY,
      startingAngles: this.data.map(function (v) {
        return v.angle
      }),
      collapsed: this.data.map(function (v) {
        return v.collapsed
      }),
      angleDragDistance: 0,
    }

    this.shiftSelectedAngle(this.data[i].angle + amount)
    this.draggedPie = null
    this.draw()
  }

  /*
   * Gets percentage of indexed slice
   */
  DraggablePiechart.prototype.getSliceSizePercentage = function (index) {
    const visibleSegments = this.getVisibleSegments()

    for (let i = 0; i < visibleSegments.length; i += 1) {
      if (visibleSegments[i].index == index) {
        return (100 * visibleSegments[i].arcSize) / TAU
      }
    }
    return 0
  }

  /*
   * Gets all percentages for each slice
   */
  DraggablePiechart.prototype.getAllSliceSizePercentages = function () {
    const visibleSegments = this.getVisibleSegments()
    const percentages = []
    for (let i = 0; i < this.data.length; i += 1) {
      if (this.data[i].collapsed) {
        percentages[i] = 0
      } else {
        for (let j = 0; j < visibleSegments.length; j += 1) {
          if (visibleSegments[j].index == i) {
            percentages[i] = (100 * visibleSegments[j].arcSize) / TAU
          }
        }
      }
    }

    return percentages
  }

  /*
   * Gets the geometry of the pie chart in the canvas
   */
  DraggablePiechart.prototype.getGeometry = function () {
    const centerX = Math.floor(this.canvas.width / 2)
    const centerY = Math.floor(this.canvas.height / 2)
    return {
      centerX,
      centerY,
      radius: Math.min(centerX, centerY) * this.radius,
    }
  }

  /*
   * Returns a segment to drag if given a close enough location
   */
  DraggablePiechart.prototype.getTarget = function (targetLocation) {
    const geometry = this.getGeometry()
    const startingAngles = []
    const collapsed = []

    const closest = {
      index: -1,
      distance: 9999999,
      angle: null,
    }

    for (let i = 0; i < this.data.length; i += 1) {
      startingAngles.push(this.data[i].angle)
      collapsed.push(this.data[i].collapsed)

      if (this.data[i].collapsed) {
        continue
      }

      const dx = targetLocation.x - geometry.centerX
      const dy = targetLocation.y - geometry.centerY
      const trueGrabbedAngle = Math.atan2(dy, dx)

      const distance = Math.abs(
        smallestSignedAngleBetween(trueGrabbedAngle, this.data[i].angle),
      )

      if (distance < closest.distance) {
        closest.index = i
        closest.distance = distance
        closest.angle = trueGrabbedAngle
      }
    }

    if (closest.distance < 0.1) {
      return {
        index: closest.index,
        angleOffset: smallestSignedAngleBetween(
          closest.angle,
          startingAngles[closest.index],
        ),
        centerX: geometry.centerX,
        centerY: geometry.centerY,
        startingAngles,
        collapsed,
        angleDragDistance: 0,
      }
    } else {
      return null
    }
  }

  /*
   * Sets segments collapsed or uncollapsed
   */
  DraggablePiechart.prototype.setCollapsed = function (index, collapsed) {
    // Flag to set position of previously collapsed to new location
    const setNewPos = this.data[index].collapsed && !collapsed

    this.data[index].collapsed = collapsed

    const visibleSegments = this.getVisibleSegments()

    // Shift other segments along to make space if necessary
    for (let i = 0; i < visibleSegments.length; i += 1) {
      // Start at this segment
      if (visibleSegments[i].index == index) {
        // Set new position
        if (setNewPos) {
          const nextSegment =
            visibleSegments[mod(i + 1, visibleSegments.length)]
          this.data[index].angle = nextSegment.angle - this.minAngle
        }

        for (let j = 0; j < visibleSegments.length - 1; j += 1) {
          const currentSegment =
            visibleSegments[mod(1 + i - j, visibleSegments.length)]
          const nextAlongSegment =
            visibleSegments[mod(i - j, visibleSegments.length)]

          const angleBetween = Math.abs(
            smallestSignedAngleBetween(
              this.data[currentSegment.index].angle,
              this.data[nextAlongSegment.index].angle,
            ),
          )

          if (angleBetween < this.minAngle) {
            this.data[nextAlongSegment.index].angle = normaliseAngle(
              this.data[currentSegment.index].angle - this.minAngle,
            )
          }
        }
        break
      }
    }

    this.draw()
  }

  /*
   * Returns visible segments
   */
  DraggablePiechart.prototype.getVisibleSegments = function () {
    const piechart = this
    // Collect data for visible segments
    const visibleSegments = []
    for (let i = 0; i < piechart.data.length; i += 1) {
      if (!piechart.data[i].collapsed) {
        const startingAngle = piechart.data[i].angle

        // Get arcSize
        let foundNextAngle = false
        for (let j = 1; j < piechart.data.length; j += 1) {
          const nextAngleIndex = (i + j) % piechart.data.length

          if (!piechart.data[nextAngleIndex].collapsed) {
            let arcSize = piechart.data[nextAngleIndex].angle - startingAngle
            if (arcSize <= 0) {
              arcSize += TAU
            }

            visibleSegments.push({
              arcSize,
              angle: startingAngle,
              format: piechart.data[i].format,
              index: i,
            })

            foundNextAngle = true
            break
          }
        }

        // Only one segment
        if (!foundNextAngle) {
          visibleSegments.push({
            arcSize: TAU,
            angle: startingAngle,
            format: piechart.data[i].format,
            index: i,
          })
          break
        }
      }
    }
    return visibleSegments
  }

  /*
   * Returns invisible segments
   */
  DraggablePiechart.prototype.getInvisibleSegments = function () {
    const piechart = this
    // Collect data for visible segments
    const invisibleSegments = []
    for (let i = 0; i < piechart.data.length; i += 1) {
      if (piechart.data[i].collapsed) {
        invisibleSegments.push({
          index: i,
          format: piechart.data[i].format,
        })
      }
    }

    return invisibleSegments
  }

  /*
   * Draws the piechart
   */
  DraggablePiechart.prototype.draw = function () {
    const piechart = this
    const context = piechart.context
    const canvas = piechart.canvas
    context.clearRect(0, 0, canvas.width, canvas.height)

    const geometry = this.getGeometry()

    const visibleSegments = this.getVisibleSegments()

    // Flags to get arc sizes and index of largest arc, for drawing order
    let largestArcSize = 0
    let indexLargestArcSize = -1

    // Get the largeset arcsize
    for (var i = 0; i < visibleSegments.length; i += 1) {
      if (visibleSegments[i].arcSize > largestArcSize) {
        largestArcSize = visibleSegments[i].arcSize
        indexLargestArcSize = i
      }
    }

    // Need to draw in correct order
    for (i = 0; i < visibleSegments.length; i += 1) {
      // Start with one *after* largest
      const index = mod(i + indexLargestArcSize + 1, visibleSegments.length)
      piechart.drawSegment(
        context,
        piechart,
        geometry.centerX,
        geometry.centerY,
        geometry.radius,
        visibleSegments[index].angle,
        visibleSegments[index].arcSize,
        visibleSegments[index].format,
        false,
      )
    }

    // Now draw invisible segments
    const invisibleSegments = this.getInvisibleSegments()
    for (i = 0; i < invisibleSegments.length; i += 1) {
      piechart.drawSegment(
        context,
        piechart,
        geometry.centerX,
        geometry.centerY,
        geometry.radius,
        0,
        0,
        invisibleSegments[i].format,
        true,
      )
    }

    // Finally draw drag nodes on top (order not important)
    for (i = 0; i < visibleSegments.length; i += 1) {
      const location = polarToCartesian(
        visibleSegments[i].angle,
        geometry.radius,
      )
      piechart.drawNode(
        context,
        piechart,
        location.x,
        location.y,
        geometry.centerX,
        geometry.centerY,
        i == piechart.hoveredIndex,
      )
    }

    piechart.onchange(piechart)
  }

  /*
   * *INTERNAL USE ONLY*
   * Moves the selected angle to a new angle
   */
  DraggablePiechart.prototype.shiftSelectedAngle = function (newAngle) {
    const piechart = this
    if (!piechart.draggedPie) {
      return
    }
    const draggedPie = piechart.draggedPie

    // Get starting angle of the target
    const startingAngle = draggedPie.startingAngles[draggedPie.index]

    // Get previous angle of the target
    const previousAngle = piechart.data[draggedPie.index].angle

    // Get diff from grabbed target start (as -pi to +pi)
    let angleDragDistance = smallestSignedAngleBetween(newAngle, startingAngle)

    // Get previous diff
    const previousDragDistance = draggedPie.angleDragDistance

    // Determines whether we go clockwise or anticlockwise
    let rotationDirection = previousDragDistance > 0 ? 1 : -1

    // Reverse the direction if we have done over 180 in either direction
    const sameDirection = previousDragDistance > 0 == angleDragDistance > 0
    const greaterThanHalf =
      Math.abs(previousDragDistance - angleDragDistance) > Math.PI

    if (greaterThanHalf && !sameDirection) {
      // Reverse the angle
      angleDragDistance =
        (TAU - Math.abs(angleDragDistance)) * rotationDirection
    } else {
      rotationDirection = angleDragDistance > 0 ? 1 : -1
    }

    draggedPie.angleDragDistance = angleDragDistance

    // Set the new angle:
    piechart.data[draggedPie.index].angle = normaliseAngle(
      startingAngle + angleDragDistance,
    )

    // Reset Collapse
    piechart.data[draggedPie.index].collapsed =
      draggedPie.collapsed[draggedPie.index]

    // Search other angles
    let shifting = true
    let collapsed = false
    const minAngle = piechart.minAngle
    let numberOfAnglesShifted = 0

    for (let i = 1; i < piechart.data.length; i += 1) {
      // Index to test each slice in order
      const index = mod(
        parseInt(draggedPie.index) + i * rotationDirection,
        piechart.data.length,
      )

      // Get angle from target start to this angle
      let startingAngleToNonDragged = smallestSignedAngleBetween(
        draggedPie.startingAngles[index],
        startingAngle,
      )

      // If angle is in the wrong direction then it should actually be OVER 180
      if (startingAngleToNonDragged * rotationDirection < 0) {
        startingAngleToNonDragged =
          (startingAngleToNonDragged * rotationDirection + TAU) *
          rotationDirection
      }

      if (piechart.collapsing) {
        // *Collapsing behaviour* when smallest angle encountered

        // Reset collapse
        piechart.data[index].collapsed = draggedPie.collapsed[index]

        const checkForSnap = !collapsed && !piechart.data[index].collapsed

        // Snap node to collapse, and prevent going any further
        if (
          checkForSnap &&
          startingAngleToNonDragged > 0 &&
          angleDragDistance > startingAngleToNonDragged - minAngle
        ) {
          piechart.data[draggedPie.index].angle = piechart.data[index].angle
          piechart.data[draggedPie.index].collapsed = true
          collapsed = true
        } else if (
          checkForSnap &&
          startingAngleToNonDragged < 0 &&
          angleDragDistance < startingAngleToNonDragged + minAngle
        ) {
          piechart.data[draggedPie.index].angle = piechart.data[index].angle
          piechart.data[index].collapsed = true
          collapsed = true
        } else {
          piechart.data[index].angle = draggedPie.startingAngles[index]
        }
      } else {
        // *Shifting behaviour* when smallest angle encountered

        // Shift all other angles along
        const shift = (numberOfAnglesShifted + 1) * minAngle

        if (
          shifting &&
          startingAngleToNonDragged > 0 &&
          angleDragDistance > startingAngleToNonDragged - shift
        ) {
          piechart.data[index].angle = normaliseAngle(
            draggedPie.startingAngles[index] +
              (angleDragDistance - startingAngleToNonDragged) +
              shift,
          )
          numberOfAnglesShifted += 1
        } else if (
          shifting &&
          startingAngleToNonDragged < 0 &&
          angleDragDistance < startingAngleToNonDragged + shift
        ) {
          piechart.data[index].angle = normaliseAngle(
            draggedPie.startingAngles[index] -
              (startingAngleToNonDragged - angleDragDistance) -
              shift,
          )
          numberOfAnglesShifted += 1
        } else {
          shifting = false
          piechart.data[index].angle = draggedPie.startingAngles[index]
        }
      }

      // console.log(JSON.stringify(piechart.data));
    }
  }

  DraggablePiechart.prototype.defaults = {
    onchange: function (piechart) {},
    radius: 0.9,
    data: [
      {
        angle: -2,
        format: { color: "#2665da", label: "Walking" },
        collapsed: false,
      },
      {
        angle: -1,
        format: { color: "#6dd020", label: "Programming" },
        collapsed: false,
      },
      {
        angle: 0,
        format: { color: "#f9df18", label: "Chess" },
        collapsed: false,
      },
      {
        angle: 1,
        format: { color: "#d42a00", label: "Eating" },
        collapsed: false,
      },
      {
        angle: 2,
        format: { color: "#e96400", label: "Sleeping" },
        collapsed: false,
      },
    ],
    collapsing: false,
    minAngle: 0.1,

    drawSegment: function (
      context,
      piechart,
      centerX,
      centerY,
      radius,
      startingAngle,
      arcSize,
      format,
      collapsed,
    ) {
      if (collapsed) {
        return
      }

      // Draw coloured segment
      context.save()
      const endingAngle = startingAngle + arcSize
      context.beginPath()
      context.moveTo(centerX, centerY)
      context.arc(centerX, centerY, radius, startingAngle, endingAngle, false)
      context.closePath()

      context.fillStyle = format.color
      context.fill()
      context.restore()

      // Draw label on top
      context.save()
      context.translate(centerX, centerY)
      context.rotate(startingAngle)

      const fontSize = Math.floor(context.canvas.height / 25)
      const dx = radius - fontSize
      const dy = centerY / 10

      context.textAlign = "right"
      context.font = fontSize + "pt Helvetica"
      context.fillText(format.label, dx, dy)
      context.restore()
    },

    drawNode: function (context, piechart, x, y, centerX, centerY, hover) {
      context.save()
      context.translate(centerX, centerY)
      context.fillStyle = "#DDDDDD"

      const rad = hover ? 25 : 15
      context.lineWidth = 2
      context.beginPath()
      context.arc(x, y, rad, 0, TAU, true)
      context.fill()
      context.stroke()
      context.restore()
    },
  }

  window.DraggablePiechart = DraggablePiechart

  /*
   * Utilities + Constants
   */

  var TAU = Math.PI * 2

  function degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180
  }

  function smallestSignedAngleBetween(target, source) {
    return Math.atan2(Math.sin(target - source), Math.cos(target - source))
  }

  function mod(n, m) {
    return ((n % m) + m) % m
  }

  function normaliseAngle(angle) {
    return mod(angle + Math.PI, TAU) - Math.PI
  }

  function polarToCartesian(angle, radius) {
    return {
      x: radius * Math.cos(angle),
      y: radius * Math.sin(angle),
    }
  }
})()

export default DraggablePiechart
