import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../../../tailwind.config.js"

export default class extends Controller {
  static targets = ["canvas"]

  static values = {
    labels: Array,
    data: Array,
    defaultYScale: Number,
  }

  connect() {
    this.chart = new Chart(this.canvasTarget, {
      type: "line",
      data: this.chartData,
      options: this.chartOptions,
    })
  }

  get chartData() {
    const colorRight = resolveConfig(tailwindConfig).theme.colors.orange["400"]

    return {
      labels: this.labelsValue,
      datasets: [
        {
          label: "",
          type: "bar",
          data: this.dataValue,
          yAxisID: "y",
          backgroundColor: colorRight,
          borderRadius: 6,
        },
      ],
    }
  }

  get chartOptions() {
    return {
      clip: false,
      spanGaps: true,
      layout: {
        padding: 5,
      },
      scales: {
        x: {
          grid: {
            drawOnChartArea: false,
          },
        },
        y: {
          type: "linear",
          display: true,
          position: "left",
          suggestedMax: this.defaultYScaleValue,
          grid: {
            drawOnChartArea: true,
            color: resolveConfig(tailwindConfig).theme.colors.gray["400"],
          },
          border: {
            dash: [2, 3],
          },
          ticks: {
            stepSize: 1,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    }
  }
}
