import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    duration: { type: Number, default: 2.5 },
  }

  connect() {
    window.setTimeout(() => {
      this.element.remove()
    }, this.durationValue * 1000)
  }

  close() {
    this.element.remove()
  }
}
