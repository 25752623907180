import { patch } from "@rails/request.js"

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: { type: String },
  }

  hide() {
    this.element.classList.add("hidden")
  }

  async open(event) {
    event.preventDefault()
    await patch("/course_promotion/open", {
      body: { origin: event.params.origin },
      contentType: "application/json",
    })
    this.hide()
    window.location.href = this.urlValue
  }
}
