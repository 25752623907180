import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["inputs", "date", "time"]

  static values = {
    switchedOn: Boolean,
    currentTime: String,
  }

  static classes = ["hidden"]

  get currentDate() {
    return this.currentTimeValue.substring(0, 10)
  }

  get currentTime() {
    return this.currentTimeValue.substring(11, 16)
  }

  toggle() {
    this.switchedOnValue = !this.switchedOnValue
  }

  switchedOnValueChanged() {
    if (!this.hasInputsTarget) return

    this.inputsTarget.classList.toggle(this.hiddenClass, !this.switchedOnValue)
    if (this.switchedOnValue) {
      if (this.dateTarget.value === "") this.dateTarget.value = this.currentDate
      if (this.timeTarget.value === "") this.timeTarget.value = this.currentTime
    } else {
      this.dateTarget.value = ""
      this.timeTarget.value = ""
    }
    this.dispatch("input-toggled", {
      detail: { switchedOn: this.switchedOnValue },
    })
  }
}
