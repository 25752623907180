import { Turbo } from "@hotwired/turbo-rails"
import "~/stylesheets/application.sass"

import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

import Appsignal from "@appsignal/javascript"
import { plugin } from "@appsignal/plugin-window-events"
import { installErrorHandler } from "@appsignal/stimulus"

window.Turbo = Turbo

const application = Application.start()

// Load all the controllers within all subdirectories of /app
const controllers = import.meta.glob("~/../**/*_controller.js", { eager: true })
registerControllers(application, controllers)

if (
  window.HEADACY_SCRIPT_ENV &&
  window.HEADACY_SCRIPT_ENV.appsignal_api_key &&
  window.HEADACY_SCRIPT_ENV.appsignal_api_key.length > 0
) {
  const appsignal = new Appsignal({
    key: window.HEADACY_SCRIPT_ENV.appsignal_api_key,
    revision: window.HEADACY_SCRIPT_ENV.revision,
  })
  appsignal.use(
    plugin({
      onerror: true,
      onunhandledrejection: true,
    }),
  )
  installErrorHandler(appsignal, application)
  window.appsignal = appsignal
}
