import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option", "progress", "hiddenField"]

  static values = {
    current: String,
    max: Number,
  }

  static classes = ["active", "inactive"]

  select({ params: { option } }) {
    this.currentValue = option
  }

  currentValueChanged(option) {
    this.dispatch("changed", { detail: { selected: option } })
    this.hiddenFieldTarget.value = option
    this.render()
  }

  render() {
    const option = this.currentValue
    this.progressTarget.style.width = `${(option * 100.0) / this.maxValue}%`

    this.optionTargets.forEach((optionElem) => {
      const isActive = parseInt(optionElem.dataset.rangeOptionParam) <= option
      optionElem.classList.toggle(this.activeClass, isActive)
      optionElem.classList.toggle(this.inactiveClass, !isActive)
    })
  }
}
