import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {
  static component = "login"

  static values = { appUserId: String }

  connect() {
    this.send("login", { app_user_id: this.appUserIdValue })
  }
}
