import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectedDate",
    "weekCalendar",
    "monthCalendar",
    "calendarWrapper",
  ]

  static outlets = ["calendar"]

  static values = {
    selectedDate: String,
    showWeek: { type: Boolean, default: true },
  }

  // actions

  selectDay({ params: { date } }) {
    this.selectedDateValue = date
  }

  toggleCalendar() {
    this.showWeekValue = !this.showWeekValue
  }

  // callbacks

  selectedDateValueChanged(newDate) {
    const newDateString = new Date(newDate).toLocaleDateString("de-de")
    const currentDateString = new Date().toLocaleDateString("de-de")

    this.selectedDateTarget.innerHTML =
      newDateString === currentDateString ? `Heute` : newDateString

    this.calendarOutlets.forEach((calendar) => {
      calendar.selectedDateValue = newDate
    })
  }

  showWeekValueChanged(showWeek) {
    this.calendarWrapperTarget.classList.toggle("!h-20", showWeek)
    this.weekCalendarTarget.classList.toggle("!h-14", showWeek)
    this.monthCalendarTarget.classList.toggle("!h-72", !showWeek)
  }
}
