import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["overlay", "closeButton"]
  static values = { slug: String }
  static classes = ["visibility"]

  connect() {
    if (!localStorage.getItem(this.slugValue)) {
      this.overlayTarget.classList.add(this.visibilityClass)
    }
  }

  close() {
    const currentTime = new Date()
    localStorage.setItem(this.slugValue, currentTime)
    this.overlayTarget.classList.remove(this.visibilityClass)
  }
}
