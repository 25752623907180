import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

export default class extends Controller {
  static targets = ["input"]

  static values = {
    attackId: Number,
    kind: String,
    addLabelUrl: String,
  }

  async add(event) {
    if (event.type === "keypress" && event.key !== "Enter") return

    event.preventDefault()
    const newLabel = this.inputTarget.value
    if (!newLabel) return

    await patch(this.addLabelUrlValue, {
      body: {
        attack_id: this.attackIdValue,
        kind: this.kindValue,
        name: newLabel,
      },
      responseKind: "turbo-stream",
    })
  }
}
