import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["screenChoice", "screenFeedback", "screenSuccess"]
  static classes = ["screenVisibility"]

  connect() {
    this.toggleVisibility(this.screenFeedbackTarget, true)
    this.toggleVisibility(this.screenSuccessTarget, true)
  }

  negative() {
    this.toggleVisibility(this.screenChoiceTarget, true)
    this.toggleVisibility(this.screenFeedbackTarget, false)
  }

  neutral() {
    this.toggleVisibility(this.screenChoiceTarget, true)
    this.toggleVisibility(this.screenFeedbackTarget, false)
  }

  submit() {
    this.toggleVisibility(this.screenFeedbackTarget, true)
    this.toggleVisibility(this.screenSuccessTarget, false)
  }

  toggleVisibility(target, isVisible) {
    target.classList.toggle(this.screenVisibilityClass, isVisible)
  }
}
