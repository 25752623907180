import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    itemId: String,
  }

  connect() {
    this.scrollToBottom()
  }

  scrollToBottom() {
    const position = document.getElementById(this.itemIdValue).offsetTop
    document.querySelector("body").scrollTo(0, position - 20)
  }
}
