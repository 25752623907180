import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["progressBar"]
  static values = { progress: Number }

  progressValueChanged() {
    this.progressBarTarget.style.width = `${this.progressValue}%`
  }

  update({ detail: { progress } }) {
    this.progressValue = progress
  }
}
