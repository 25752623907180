import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = [
    "file",
    "playerBar",
    "publicPauseButton",
    "publicPlayButton",
    "playerTitle",
    "currentTime",
    "totalPlayTime",
    "progressInput",
    "soundCard",
    "readerButton",
    "readerOverlay",
    "readerOverlayScrollContainer",
    "readerTitle",
    "readerContent",
    "readerContentTurboFrame",
  ]

  static values = {
    playerTitle: { type: String, default: "" },
    currentSoundUrl: { type: String, default: "" },
    currentSoundSlug: { type: String, default: "" },
    currentTimeInSeconds: { type: Number, default: 0 },
    isPlaying: { type: Boolean, default: false },
    isLooping: { type: Boolean, default: false },
    duration: { type: Number, default: 0 },
    autoPlayEnabled: { type: Boolean, default: true },
  }

  static classes = ["visibility"]

  connect() {
    this.playerBarTarget.classList.add(...this.visibilityClasses)
    this.progressUpdateIntervalId = null
    this.updatePlayerTargetVisibility()
    this.resetProgress()
    this.setupEventListeners()
    this.setupMediaSession()
  }

  setupMediaSession() {
    if (this.isMediaSessionSupported()) {
      navigator.mediaSession.setActionHandler("play", this.play.bind(this))
      navigator.mediaSession.setActionHandler("pause", this.pause.bind(this))
      // TODO: we have not stop, but maybe it should pause?
      // navigator.mediaSession.setActionHandler("stop", () => { this.pause() })
    }
  }

  updateMediaSession() {
    if (this.isMediaSessionSupported()) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: `Entspannung: ${this.playerTitleValue}`,
        artist: "headacy",
        album: "The Ultimate Collection (Remastered)",
        artwork: [
          {
            src: this.mediaImage,
            sizes: "96x96",
            type: "image/jpg",
          },
        ],
      })
    }
  }

  setupEventListeners() {
    this.fileTarget.addEventListener("ended", () => {
      if (!this.isLoopingValue) {
        console.log("%cEnded", "background: red; color: white")
        this.currentSoundUrlValue = ""
        this.isPlayingValue = false
        this.resetProgress()
        this.updatePlayerTargetVisibility()
        this.trackAction("audio_ended")
      }
    })
    this.progressInputTarget.addEventListener("touchstart", () => {
      this.pause()
    })
    this.progressInputTarget.addEventListener("change", () => {
      const newPositionInPercent = this.progressInputTarget.value
      const newPositionInSeconds =
        (this.durationValue * newPositionInPercent) / 100
      this.fileTarget.currentTime = newPositionInSeconds
    })
    this.progressInputTarget.addEventListener("touchend", () => {
      this.resume()
    })
  }

  soundCardTargetConnected(element) {
    this.updateSoundCardPausePlayButtonsVisibility(element)
  }

  currentSoundUrlValueChanged(current, previous) {
    // NB: When the user switches the audio source while listening, we force pause the player first,
    // so that the player progress and player buttons are updated. See isPlayingValueChanged-callback.
    if (current.length > 0 && previous.length > 0) {
      this.isPlayingValue = false
    }

    if (this.currentSoundUrlValue.length > 0) {
      this.fileTarget.src = this.currentSoundUrlValue
      if (this.autoPlayEnabledValue) {
        this.isPlayingValue = true
      }
    } else {
      this.fileTarget.src = ""
      this.isPlayingValue = false
    }

    if (this.currentSoundSlugValue.length > 0) {
      this.readerContentTurboFrameTarget.src = `/discover/transcription/${this.currentSoundSlugValue}`
      this.readerTitleTarget.innerHTML = this.playerTitleValue
    }

    this.updateSoundCardsPausePlayButtonsVisibility()
    this.updatePlayerTargetVisibility()
  }

  currentTimeInSecondsValueChanged(current, previous) {
    const progress =
      Math.round(((current * 100) / this.durationValue) * 100) / 100
    this.progressInputTarget.value = current
    this.currentTimeTarget.innerHTML = this.formatSeconds(Math.round(current))
    if (this.isLoopingValue && progress > 99) {
      this.fileTarget.currentTime = 0
      console.log("%cmanual loop initated", "background: gray; color: white")
    }
  }

  handleTurboFrameResponse(event) {
    const statusCode = event.detail.fetchResponse.statusCode
    if (statusCode === 200) {
      this.showReaderButton()
    } else {
      this.hideReaderButton()
    }
  }

  hideReaderButton() {
    this.readerButtonTarget.classList.add("hidden")
  }

  showReaderButton() {
    this.readerButtonTarget.classList.remove("hidden")
  }

  isLoopingValueChanged() {
    this.fileTarget.loop = this.isLoopingValue
  }

  playerTitleValueChanged() {
    this.playerTitleTarget.innerHTML = this.playerTitleValue
  }

  durationValueChanged() {
    this.totalPlayTimeTarget.innerHTML = this.formatSeconds(this.durationValue)
  }

  isPlayingValueChanged(x, y) {
    window.audioPlaying = this.isPlayingValue

    if (this.isPlayingValue) {
      this.fileTarget.play()
      this.startProgressUpdateInterval()
      this.updateMediaSession()
    } else {
      this.fileTarget.pause()
      this.stopProgressUpdateInterval()
    }
    this.updatePlayerButtons()
    this.updateSoundCardsPausePlayButtonsVisibility()
  }

  load(event) {
    console.log("%cLoad", "background: green; color: white")

    this.autoPlayEnabledValue = false
    const data = event.currentTarget.dataset
    this.updatePlayerData(data)
  }

  hide() {
    console.log("%cHide", "background: green; color: white")
    this.isPlayingValue = false
    this.playerBarTarget.classList.add(...this.visibilityClasses)
  }

  show() {
    console.log("%cShow", "background: green; color: white")
    this.playerBarTarget.classList.remove(...this.visibilityClasses)
  }

  play(event) {
    console.log("%cPlay", "background: green; color: white")
    // unshift player position when player came from coaching
    // and elevate reader overlay
    // (refactor later)
    this.readerOverlayTarget.classList.remove("!z-70")

    this.autoPlayEnabledValue = true
    this.trackAction("audio_started")

    const data = event.currentTarget.dataset
    this.updatePlayerData(data)
  }

  updatePlayerData(data) {
    this.isLoopingValue = data.soundLoop === "true"
    this.playerTitleValue = data.soundTitle
    this.durationValue = data.soundDuration
    this.isPlayingValue = false
    this.currentSoundUrlValue = data.soundUrl
    this.currentSoundSlugValue = data.soundSlug
    this.currentTimeInSecondsValue = 0
    this.mediaImage = data.soundMediaImage
  }

  pause() {
    console.log("%cPause", "background: yellow; color: black")
    this.isPlayingValue = false

    this.trackAction("audio_paused")
  }

  resume() {
    console.log("%cResume", "background: aqua; color: black")
    this.isPlayingValue = true

    this.trackAction("audio_resumed")
  }

  startProgressUpdateInterval() {
    this.progressUpdateIntervalId = setInterval(() => {
      this.updateCurrentTime()
    }, 100)
  }

  updateCurrentTime() {
    this.currentTimeInSecondsValue = this.fileTarget.currentTime
  }

  stopProgressUpdateInterval() {
    if (this.progressUpdateIntervalId) {
      clearInterval(this.progressUpdateIntervalId)
    }
  }

  resetProgress() {
    this.progressInputTarget.value = "0"
    this.currentTimeTarget.innerHTML = "00:00"
  }

  updatePlayerButtons() {
    this.publicPlayButtonTarget.classList.toggle("hidden", this.isPlayingValue)
    this.publicPauseButtonTarget.classList.toggle(
      "hidden",
      !this.isPlayingValue,
    )
  }

  updateSoundCardsPausePlayButtonsVisibility() {
    this.soundCardTargets.forEach((element) =>
      this.updateSoundCardPausePlayButtonsVisibility(element),
    )
  }

  updateSoundCardPausePlayButtonsVisibility(element) {
    const playButton = element.querySelector("[data-name=singlePlayButton]")
    const pauseButton = element.querySelector("[data-name=singlePauseButton]")

    if (element.dataset.soundUrl !== this.currentSoundUrlValue) {
      playButton.classList.add("hidden")
      pauseButton.classList.add("hidden")
    } else {
      playButton.classList.toggle("hidden", this.isPlayingValue)
      pauseButton.classList.toggle("hidden", !this.isPlayingValue)
    }
  }

  updatePlayerTargetVisibility() {
    if (this.currentSoundUrlValue.length !== 0) {
      this.playerBarTarget.classList.remove(...this.visibilityClasses)
    } else {
      this.playerBarTarget.classList.add(...this.visibilityClasses)
    }
  }

  toggleReader() {
    const wasOpen = !this.readerOverlayTarget.classList.contains("hidden")
    this.readerButtonTarget.classList.toggle("text-orange-500")
    if (wasOpen) {
      this.readerOverlayScrollContainerTarget.scrollTo(0, 0)
    }
    this.readerOverlayTarget.classList.toggle("hidden")
  }

  formatSeconds(seconds) {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    const formattedMinutes = String(minutes).padStart(2, "0")
    const formattedSeconds = String(remainingSeconds).padStart(2, "0")
    return `${formattedMinutes}:${formattedSeconds}`
  }

  trackAction(name, details = {}) {
    const payload = {}
    if (this.currentSoundSlugValue.length > 0) {
      payload.sound_slug = this.currentSoundSlugValue
    }

    post(`/events?action_name=${name}`, {
      body: { details: payload },
      contentType: "application/json",
    })
  }

  isMediaSessionSupported() {
    return "mediaSession" in navigator
  }
}
