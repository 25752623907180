import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100"

import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../../tailwind.config.js"
import { get } from "@rails/request.js"

Chart.register(ChartjsPluginStacked100)
export default class extends Controller {
  static targets = ["canvas", "metric", "timeRange", "noResultHint"]

  static values = {
    dataUrl: String,
    timeRange: { type: String, default: "week" },
  }

  connect() {
    this.chart = new Chart(this.canvasTarget, {
      type: "bar",
      data: this.chartData,
      options: this.chartOptions,
    })
  }

  selectTimeRange({ detail }) {
    this.timeRangeValue = detail.timeRange
  }

  timeRangeValueChanged(newTimeRange) {
    this.render()
  }

  // actions
  async render() {
    const url = new URL(this.dataUrlValue)
    const searchOptions = { time_range: this.timeRangeValue }
    searchOptions.metric = "effect"
    url.search = new URLSearchParams(searchOptions).toString()

    const response = await get(url.toString())
    if (response.ok) {
      const data = await response.json
      this.updateChart(data)
    }
  }

  updateChart(data) {
    const isEmpty = Object.keys(data).some((key) => data[key].length === 0)

    this.canvasTarget.classList.toggle("!invisible", isEmpty)
    this.noResultHintTarget.classList.toggle("hidden", !isEmpty)

    this.chart.data.labels = data.labels
    this.chart.data.datasets[0].data = data.no_effect
    this.chart.data.datasets[1].data = data.ok
    this.chart.data.datasets[2].data = data.good

    this.chart.update()
  }

  // private

  get chartData() {
    const colors = resolveConfig(tailwindConfig).theme.colors

    return {
      labels: [],
      datasets: [
        {
          label: "gar nicht",
          data: [],
          backgroundColor: colors.orange["400"],
        },
        {
          label: "mittel",
          data: [],
          backgroundColor: colors.lavender["300"],
        },
        {
          label: "gut",
          data: [],
          backgroundColor: colors.lavender["400"],
        },
      ],
    }
  }

  get chartOptions() {
    return {
      indexAxis: "y",
      scales: {
        x: {
          grid: {
            drawOnChartArea: false,
            drawBorder: false,
          },
        },
        y: {
          grid: {
            drawOnChartArea: false,
            drawBorder: false,
          },
        },
      },
      plugins: {
        stacked100: { enable: true, precision: 0 },
        legend: {
          position: "bottom",
        },
        tooltip: {
          displayColors: false,
        },
      },
    }
  }

  percentage(array, value) {
    let sum = 0
    array.map((data) => (sum += data))
    return ((value * 100) / sum).toFixed(0)
  }
}
