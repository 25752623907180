import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import Swiper from "swiper"
import { Pagination } from "swiper/modules"

import "swiper/css"
import "swiper/css/pagination"

export default class extends Controller {
  static targets = [
    "swiper",
    "title",
    "nextButton",
    "finishButton",
    "pagination",
  ]

  static values = { titles: Array, previousUrl: String }
  static classes = ["hidden"]

  connect() {
    this.swiper = new Swiper(this.swiperTarget, {
      direction: "horizontal",
      pagination: { el: this.paginationTarget },
      on: { slideChange: this.slideChange.bind(this) },
      modules: [Pagination],
    })
    document.documentElement.classList.add("overscroll-none")
    this.slideChange()
  }

  disconnect() {
    document.documentElement.classList.remove("overscroll-none")
  }

  slideChange() {
    if (undefined === this.swiper) return false
    this.titleTarget.innerHTML = this.titlesValue[this.swiper.activeIndex]

    const lastSlide = this.isLastSlide
    this.nextButtonTarget.classList.toggle(this.hiddenClass, lastSlide)
    this.finishButtonTarget.classList.toggle(this.hiddenClass, !lastSlide)
  }

  previous() {
    if (undefined === this.swiper) return false
    this.isFirstSlide
      ? Turbo.visit(this.previousUrlValue, { action: "advance" })
      : this.swiper.slidePrev()
  }

  next() {
    this.swiper.slideNext()
  }

  get isFirstSlide() {
    return this.swiper.activeIndex === 0
  }

  get isLastSlide() {
    return this.swiper.activeIndex === this.swiper.slides.length - 1
  }
}
