import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import "swiper/css"

export default class extends Controller {
  static targets = ["swiper", "activeSlide", "monthTitle", "day"]

  static classes = ["selected"]

  static values = {
    type: String,
    selectedDate: String,
  }

  async connect() {
    this.swiper = new Swiper(this.swiperTarget, {
      direction: this.isWeek ? "horizontal" : "vertical",
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      initialSlide: this.activeSlideTarget.dataset.slideIndex,
      on: {
        slideChange: this.slideChange.bind(this),
      },
    })
    this.setHeight()
  }

  slideChange() {
    this.setHeight()
    this.updateMonthTitle()
  }

  setHeight() {
    if (undefined === this.swiper || this.isWeek || !this.currentSlide)
      return false

    const offset = { 4: 0, 5: 2, 6: 4 }[this.currentSlide.dataset.calendarRows]
    const swiperHeight = `${9 + offset}rem`
    const wrapperHeight = `${14.5 + offset}rem`

    this.swiperTarget.style.height = swiperHeight
    document.getElementById("calendar-wrapper").style.height = wrapperHeight
  }

  updateMonthTitle() {
    if (undefined === this.swiper || this.isWeek) return false

    this.hasMonthTitleTarget &&
      (this.monthTitleTarget.innerHTML = this.currentSlide.dataset.monthTitle)
  }

  get currentSlide() {
    return this.swiper.slides[this.swiper.activeIndex]
  }

  // callbacks

  selectedDateValueChanged(newDate, oldDate) {
    this.dayTargets.forEach((day) => {
      if (day.dataset.homeDateParam === newDate) {
        day.classList.add(this.selectedClass)
        this.slideTo(day.closest(".swiper-slide").dataset.slideIndex)
      }
      if (day.dataset.homeDateParam === oldDate)
        day.classList.remove(this.selectedClass)
    })
  }

  slideTo(slideIndex) {
    if (undefined === this.swiper) return false
    this.swiper.slideTo(slideIndex)
  }

  get isWeek() {
    return this.typeValue === "week"
  }

  get isMonth() {
    return this.typeValue === "month"
  }
}
