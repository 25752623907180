import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {
  static component = "lifecycle"

  connect() {
    this.send("register", {}, this.lifecycleCallback.bind(this))
  }

  async lifecycleCallback(payload) {
    if (payload.data.secondsSincePause > 60 && !window.audioPlaying) {
      window.location.reload()
    }
  }
}
