import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checked"]

  static values = {
    checked: Boolean,
  }

  static classes = ["hidden"]

  toggle() {
    this.checkedValue = !this.checkedValue
  }

  checkedValueChanged() {
    this.checkedTarget.classList.toggle(this.hiddenClass, !this.checkedValue)
  }
}
