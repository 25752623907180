import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["timeRange"]

  static values = {
    timeRange: { type: String, default: "month" },
  }

  static classes = ["active"]

  select({ params: { option } }) {
    this.timeRangeValue = option
  }

  timeRangeValueChanged(newTimeRange) {
    this.dispatch("changed", { detail: { timeRange: newTimeRange } })
    this.timeRangeTargets.forEach((timeLink) => {
      const isActive = timeLink.dataset.timeRangeOptionParam === newTimeRange
      timeLink.classList.toggle(this.activeClass, isActive)
    })
  }
}
