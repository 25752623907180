import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["meal", "addMeal"]

  connect() {
    this.nextIndex = this.mealTargets.length
    this.renderButtons()
  }

  add({ currentTarget, params: { type, time, name } }) {
    if (this.mealTargets.length >= 5) {
      return
    }
    const lastMeal = this.mealTargets[this.mealTargets.length - 1]
    // const index = this.mealTargets.length
    const newMeal = lastMeal.cloneNode(true)
    const typeInput = newMeal.querySelector("input[type='hidden']")
    this.setNewInputsValues(typeInput, this.nextIndex, type)
    const timeInput = newMeal.querySelector("input[type='time']")
    this.setNewInputsValues(timeInput, this.nextIndex, time)
    newMeal.querySelector("[data-food-target='name']").innerText = name
    newMeal.querySelector("svg").innerHTML = currentTarget.querySelector(
      "template[data-food-target='icon']",
    ).innerHTML
    lastMeal.after(newMeal)
    this.nextIndex++
    this.renderButtons()
  }

  renderButtons() {
    this.addMealTarget.classList.toggle("hidden", this.mealTargets.length >= 5)
  }

  setNewInputsValues(element, index, value) {
    element.value = value
    element.name = element.name.replace(/\[\d+\]/, `[${index}]`)
    element.id = element.id.replace(/_\d+_/, `_${index}_`)
  }
}
